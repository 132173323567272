app-root {
  flex-direction: column;
}

app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

app-main {
  margin-top: 73px;
  flex-direction: column;
  flex: 1;
}

.page-wrapper {
  height: 100%;
}

.container-fluid {
  padding: 0 30px 0px 30px;
}

@media (min-width: 1100px) {
  .container-fluid {
    max-width: 100%;
  }
}

/* Take courses */

.course-card .course-img {
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.course-card .card-body {
  height: 230px;
}

.course-card .card-text {
  margin-bottom: 10px;
}

.content-start-course-button {
  position: absolute;
  bottom: 50px;
}

.content-course-tag {
  width: 100%;
  text-align: center;
  float: left;
  height: 25px;
}

.tooltip-content5 {
  width: 600px;
  font-size: 14px;
}

a.mytooltip {
  z-index: 0;
}

/* Text truncate */

.text-truncate-multiple-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  white-space: normal;
}

/* Tooltip */
.tooltip {
  pointer-events: none;
}

/* Sidebar menu */

@media (min-width: 768px) {
  .single-column.mini-sidebar .page-wrapper {
    margin-left: 0px;
  }
}

.sidebar-nav .has-arrow {
  cursor: pointer;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #1e88e5;
}

a:not([href]):not([tabindex]) {
  color: #607d8b;
}

.mini-sidebar .sidebar-nav ul li.nav-separator {
  display: list-item;
  max-width: 50px;
  padding: 1px 25px 0px 25px;
  background-color: #e1e2e2;
}

.sidebar-nav > ul > li {
  margin-bottom: 5px;
  margin-top: 5px;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  cursor: pointer;
}

.flex-grow {
  flex: 1 0 auto;
}

.btn-contextual-file {
  color: #c7c7c7 !important;
}

.btn-contextual-file:focus {
  outline: none;
  box-shadow: none;
}

.btn-contextual-file.dropdown-toggle::after {
  width: 15px;
  height: 15px;
  content: '\F1D9';
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font: normal normal normal 24px/1 'Material Design Icons';
  font-size: 16px;
  margin-top: 0.255em;
}

.hidden {
  display: none;
}

div.logo {
  height: auto;
}

.toast-login {
  min-width: 25rem !important;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.toast-container {
  display: flex; /* Activa flexbox */
  flex-direction: column; /* Los elementos se apilan verticalmente */
  align-items: flex-end;
}
